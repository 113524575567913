"use strict";
document.addEventListener("DOMContentLoaded", () => {
  const modalId = "LimitAmendmentsModal";
  const modalEl = document.getElementById(modalId);
  const amendButton = document.getElementById("amend-button");
  const limitAmendments = modalEl && JSON.parse(modalEl.dataset.limitAmendments);
  if (!amendButton || !limitAmendments || document.querySelector('a[href^="/users/sign_in"]')) {
    return;
  }
  modalEl.querySelectorAll("a").forEach((aEl) => {
    aEl.addEventListener("click", () => {
      window.Decidim.currentDialogs[modalId].close();
    });
  });
  amendButton.addEventListener("click", (event) => {
    const modal = window.Decidim.currentDialogs[modalId];
    if (modal) {
      event.preventDefault();
      event.stopPropagation();
      modal.open();
    }
  });
});
